import { types } from 'react-alert';

import { TeamMemberColor } from 'types';
import {
  TextColor,
  DotsColor,
  BadgeColor,
  ErrorColor,
  AvatarColor,
  ButtonColors,
  CashCardType,
  ListCardStyle,
  CheckboxColor,
  TabCountColor,
  EventInventory,
  ButtonStyleTypes,
  SalesCompatitionPosition,
} from 'types/enums';

import { badgeDefaultState } from '../constants';

//  white
export const white: string = '#F9F9F9';
export const whiteHover: string = '#EEEBEB';
export const whiteAction: string = '#F0F0F0';

//  blue
export const blue: string = '#2576FA';
export const blueHover: string = '#1766E8';
export const blueAction: string = '#0F5EDE';

export const secondaryBlue: string = '#213452';
export const secondaryBlueHover: string = '#273D61';
export const secondaryBlueAction: string = '#2D4670';

export const tetrieryBlue: string = '#202731';
export const tetrieryBlueHover: string = '#28313D';
export const tetrieryBlueAction: string = '#303B49';

export const additionalBlue: string = '#488EFF';

//  black
export const black: string = '#202020';
export const blackHover: string = '#343434';
export const blackAction: string = '#484848';
export const additionalBlack: string = '#040404';

//  grey
export const grey: string = '#484848';
export const greyHover: string = '#363636';
export const greyAction: string = '#2A2A2A';

export const secondaryGrey: string = '#4A4A4A';
export const secondaryGreyHover: string = '#545454';
export const secondaryGreyAction: string = '#5E5E5E';

export const tetrieryGrey: string = '#292929';
export const tetrieryGreyHover: string = '#333333';
export const tetrieryGreyAction: string = '#383838';

export const additionalGrey: string = '#8F8F8F';

//  green
export const green: string = '#01B55B';
export const greenHover: string = '#01974C';
export const greenAction: string = '#018342';
export const everglade: string = '#1A3C2B';
export const tePapaGreen: string = '#203C45';

export const secondaryGreen: string = '#1A3C2B';
export const secondaryGreenHover: string = '#204A35';
export const secondaryGreenAction: string = '#23513A';

export const tetrieryGreen: string = '#1E2C25';
export const tetrieryGreenHover: string = '#26382F';
export const tetrieryGreenAction: string = '#2A3E34';

export const additionalGreen: string = '#01B55B';

//  red
export const red: string = '#FE4B4B';
export const redHover: string = '#E82E2E';
export const redAction: string = '#C82020';

export const secondaryRed: string = '#4B2828';
export const secondaryRedHover: string = '#582F2F';
export const secondaryRedAction: string = '#5F3333';

export const tetrieryRed: string = '#322323';
export const tetrieryRedHover: string = '#3E2B2B';
export const tetrieryRedAction: string = '#442F2F';

export const additionalRed: string = '#FE4B4B';

// purple
export const purple: string = '#C15CAB';
export const purpleHover: string = '#B8469F';
export const purpleAction: string = '#A23E8C';

export const secondaryPurple: string = '#462F41';
export const secondaryPurpleHover: string = '#52374C';
export const secondaryPurpleAction: string = '#583B52';

export const tetrieryPurple: string = '#30262E';
export const tetrieryPurpleHover: string = '#3B2F39';
export const tetrieryPurpleAction: string = '#41343E';

export const additionalPurple: string = '#A80884';

//  yellow
export const yellow: string = '#DAA403';
export const yellowHover: string = '#BC8D03';
export const yellowAction: string = '#9E7603';

export const secondaryYellow: string = '#483D1B';
export const secondaryYellowHover: string = '#574A21';
export const secondaryYellowAction: string = '#5E5024';

export const tetrieryYellow: string = '#312C1E';
export const tetrieryYellowHover: string = '#3E3726';
export const tetrieryYellowAction: string = '#443D2A';

export const additionalYellow: string = '#DAA403';

//  cerulean
export const cerulean: string = '#0D8EB6';
export const ceruleanHover: string = '#0B7899';
export const ceruleanAction: string = '#0A6986';

export const secondaryCerulean: string = '#203C45';
export const secondaryCeruleanHover: string = '#264853';
export const secondaryCeruleanAction: string = '#294E5A';

export const tetrieryCerulean: string = '#202C30';
export const tetrieryCeruleanHover: string = '#28373C';
export const tetrieryCeruleanAction: string = '#2C3D42';

export const additionalCerulean: string = '#0BA5D3';

//  orange
export const orange: string = '#C8791B';
export const orangeHover: string = '#B06A18';
export const orangeAction: string = '#985C15';

export const secondaryOrange: string = '#523F27';
export const secondaryOrangeHover: string = '#594730';
export const secondaryOrangeAction: string = '#675641';

export const tetrieryOrange: string = '#37312A';
export const tetrieryOrangeHover: string = '#3F3933';
export const tetrieryOrangeAction: string = '#4F4A44';

export const additionalOrange: string = '#DAA403';

export const pigmentIndigo: string = '#D499EF';
export const pigmentIndigoHover: string = '#C47AE6';
export const pigmentIndigoAction: string = '#A757CA';

export const secondaryPigmentIndigo: string = '#493A50';
export const secondaryPigmentIndigoHover: string = '#53425E';
export const secondaryPigmentIndigoAction: string = '#5D486A';

export const tertiaryPigmentIndigo: string = '#302A33';
export const tertiaryPigmentIndigoHover: string = '#3C3243';
export const tertiaryPigmentIndigoAction: string = '#473A51';

const regularShadow: string =
  '0 8px 10px rgba(0, 0, 0, 0.3), 0 3px 14px rgba(0, 0, 0, 0.04), 0 4px 5px rgba(0, 0, 0, 0.04)';

const infoShadow: string =
  '0px 1px 16px rgba(0, 0, 0, 0.3), 1px 8px 24px rgba(0, 0, 0, 0.04)';

const cardShadow: string = '0px 8px 24px rgba(0, 0, 0, 0.4)';

const common = {
  icon: white,
  divider: tetrieryGrey,
  border: secondaryGrey,
};

const avatar = Object.freeze({
  icon: white,
  imageIcon: white,
  statusBorder: white,
  border: additionalGrey,
  background: greyAction,
  imageMask: { hover: `${black}99`, action: `${black}B3` },
});

const circle = Object.freeze({
  bg: black,
  border: white,
});

const badge = Object.freeze({
  [BadgeColor.Tag]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      bg: black,
      text: white,
      border: 'inherit',
    },
  },
  [BadgeColor.New]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      bg: white,
      text: black,
      border: 'inherit',
    },
  },
  [BadgeColor.Between]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      bg: tePapaGreen,
      text: 'inherit',
      border: 'inherit',
    },
  },
  [BadgeColor.Pink]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryPurple,
    },
  },
  [BadgeColor.AdditionalGrey]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: white,
      bg: tetrieryGrey,
      border: 'inherit',
    },
  },
  [BadgeColor.LightGrey]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: white,
      border: 'inherit',
      bg: secondaryGreyAction,
    },
  },
  [BadgeColor.TetrieryCerulean]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: white,
      border: 'inherit',
      bg: tetrieryCerulean,
    },
  },
  [BadgeColor.Grey]: {
    dot: greyAction,
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: white,
      bg: greyHover,
      border: 'inherit',
    },
  },
  [BadgeColor.Transparent]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      bg: black,
      text: white,
      border: `1px solid ${secondaryGrey}`,
    },
  },
  [BadgeColor.SecondaryCerulean]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: white,
      border: 'inherit',
      bg: secondaryCeruleanHover,
    },
  },
  [BadgeColor.TertiaryGreen]: {
    hover: badgeDefaultState,
    default: {
      ...badgeDefaultState,
      bg: tetrieryGreen,
    },
    active: {
      bg: green,
      text: white,
      border: 'inherit',
    },
  },
  [BadgeColor.SecondaryRed]: {
    active: {
      ...badgeDefaultState,
      bg: additionalRed,
    },
    hover: {
      ...badgeDefaultState,
      bg: secondaryRedAction,
    },
    default: {
      ...badgeDefaultState,
      bg: secondaryRedHover,
    },
  },
  [BadgeColor.RedAlert]: {
    hover: {
      text: white,
      bg: redHover,
      border: 'inherit',
    },
    active: {
      text: white,
      bg: redAction,
      border: 'inherit',
    },
    default: {
      text: white,
      border: 'inherit',
      bg: additionalRed,
    },
  },
  [BadgeColor.SecondaryGreen]: {
    hover: {
      ...badgeDefaultState,
      bg: secondaryGreenAction,
    },
    default: {
      ...badgeDefaultState,
      bg: secondaryGreenHover,
    },
    active: {
      ...badgeDefaultState,
      text: black,
      bg: additionalGreen,
    },
  },
  [BadgeColor.SecondaryYellow]: {
    hover: {
      ...badgeDefaultState,
      bg: secondaryYellowAction,
    },
    default: {
      ...badgeDefaultState,
      bg: secondaryYellowHover,
    },
    active: {
      ...badgeDefaultState,
      text: black,
      bg: additionalYellow,
    },
  },
  [BadgeColor.TertiaryOrange]: {
    dot: orangeHover,
    hover: {
      ...badgeDefaultState,
      bg: tetrieryOrangeHover,
    },
    active: {
      ...badgeDefaultState,
      bg: tetrieryOrangeHover,
    },
    default: {
      ...badgeDefaultState,
      bg: tetrieryOrangeHover,
    },
  },
  [BadgeColor.Blue]: {
    default: {
      text: 'inherit',
      bg: secondaryBlue,
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryBlueHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryBlueAction,
    },
  },
  [BadgeColor.TetrieryBlue]: {
    default: {
      text: 'inherit',
      bg: tetrieryBlue,
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: tetrieryBlueHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: tetrieryBlueAction,
    },
  },
  [BadgeColor.Red]: {
    dot: additionalRed,
    default: {
      text: 'inherit',
      bg: secondaryRed,
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryRedHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryRedAction,
    },
  },
  [BadgeColor.Yellow]: {
    dot: yellow,
    default: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryYellow,
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryYellowHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryYellowAction,
    },
  },
  [BadgeColor.Green]: {
    dot: additionalGreen,
    default: {
      bg: everglade,
      text: 'inherit',
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryGreenHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryGreenAction,
    },
  },
  [BadgeColor.SecondaryPigmentIndigo]: {
    dot: pigmentIndigo,
    default: {
      text: white,
      border: 'inherit',
      bg: secondaryPigmentIndigo,
    },
    hover: {
      text: white,
      border: 'inherit',
      bg: secondaryPigmentIndigoHover,
    },
    active: {
      text: white,
      border: 'inherit',
      bg: secondaryPigmentIndigoAction,
    },
  },
  [BadgeColor.AdditionalRed]: {
    active: {
      ...badgeDefaultState,
      bg: additionalRed,
      border: `1px solid ${additionalRed}`,
    },
    hover: {
      text: additionalRed,
      bg: secondaryRedHover,
      border: `1px solid ${additionalRed}`,
    },
    default: {
      ...badgeDefaultState,
      text: additionalRed,
      border: `1px solid ${additionalRed}`,
    },
  },
});

const checkbox = Object.freeze({
  svg: { default: black, checked: white },
  [CheckboxColor.Blue]: {
    default: { bg: blue, box: tetrieryGrey, border: secondaryGrey },
    checked: { bg: blue, box: secondaryBlue, border: secondaryBlue },
    hover: { bg: blueHover, box: tetrieryBlue, border: secondaryBlue },
    disabled: {
      bg: grey,
      box: tetrieryGrey,
      border: secondaryGrey,
    },
    active: {
      bg: blueAction,
      box: tetrieryBlueHover,
      border: tetrieryBlueHover,
    },
  },
  [CheckboxColor.Green]: {
    default: { bg: green, box: tetrieryGrey, border: secondaryGrey },
    checked: { bg: green, box: secondaryGreen, border: secondaryGreen },
    hover: { bg: greenHover, box: tetrieryGreen, border: secondaryGreen },
    disabled: {
      bg: grey,
      box: tetrieryGrey,
      border: secondaryGrey,
    },
    active: {
      bg: greenAction,
      box: tetrieryGreenHover,
      border: tetrieryGreenHover,
    },
  },
  [CheckboxColor.Red]: {
    hover: { bg: white, box: secondaryRed, border: secondaryRed },
    disabled: {
      bg: grey,
      box: tetrieryGrey,
      border: secondaryGrey,
    },
    active: {
      bg: white,
      box: secondaryRedHover,
      border: secondaryRedHover,
    },
    checked: {
      bg: white,
      box: tetrieryRedAction,
      border: tetrieryRedAction,
    },
    default: {
      bg: white,
      box: tetrieryRedAction,
      border: tetrieryRedAction,
    },
  },
  [CheckboxColor.SecondaryBlue]: {
    hover: { bg: white, box: secondaryBlueHover, border: secondaryBlueHover },
    disabled: {
      bg: grey,
      box: tetrieryGrey,
      border: secondaryGrey,
    },
    default: {
      bg: white,
      box: secondaryBlue,
      border: secondaryBlue,
    },
    checked: {
      bg: white,
      box: secondaryBlue,
      border: secondaryBlue,
    },
    active: {
      bg: white,
      box: secondaryBlueAction,
      border: secondaryBlueAction,
    },
  },
});

const radioButton = Object.freeze({
  default: { circle: blue, bg: tetrieryGrey, border: secondaryGrey },
  checked: { circle: blue, bg: secondaryBlue, border: secondaryBlue },
  hover: { bg: tetrieryBlue, circle: blueHover, border: secondaryBlue },
  disabled: {
    circle: grey,
    bg: tetrieryGrey,
    border: secondaryGrey,
  },
  active: {
    circle: blueAction,
    bg: tetrieryBlueHover,
    border: tetrieryBlueHover,
  },
});

const switchCheckbox = Object.freeze({
  slider: {
    default: white,
    disabled: blackAction,
    shadow: regularShadow,
  },
  redBg: {
    default: red,
    checked: green,
    hover: redHover,
    disabled: redAction,
  },
  bg: {
    checked: green,
    hover: greyHover,
    default: greyHover,
    disabled: greyAction,
  },
});

const sidebar = Object.freeze({
  disabled: grey,
  default: black,
  border: blackHover,
  selected: secondaryBlue,
  hover: secondaryBlueHover,
  active: secondaryBlueAction,
  svg: { default: white, selected: blue },
  boxShadow: `0px 4px 7px 0px ${additionalBlack}`,
});

const subMenu = Object.freeze({
  hover: blackHover,
  active: blackAction,
  selected: { text: white, bg: secondaryBlue },
  title: { bg: tetrieryGrey, color: additionalGrey },
  disabled: { bg: blackAction, color: additionalGrey },
});

const button = Object.freeze({
  [ButtonStyleTypes.Outline]: {
    [ButtonColors.LightRed]: null,
    [ButtonColors.WhiteBlue]: null,
    [ButtonColors.LightBlue]: null,
    [ButtonColors.LightGreen]: null,
    [ButtonColors.LightPurple]: null,
    [ButtonColors.LightYellow]: null,
    [ButtonColors.TetrieryGrey]: null,
    [ButtonColors.PigmentIndigo]: null,
    [ButtonColors.LightCerulean]: null,
    [ButtonColors.Blue]: {
      default: { text: blue, border: blue, bg: 'transparent' },
      disabled: { text: blue, border: blue, bg: 'transparent' },
      hover: { text: blueHover, bg: tetrieryBlue, border: blueHover },
      action: { text: blueAction, border: blueAction, bg: tetrieryBlueHover },
    },
    [ButtonColors.BlackWhite]: {
      disabled: { bg: black, text: white, border: 'none' },
      default: { text: black, border: black, bg: 'transparent' },
      hover: { border: 'none', text: blackHover, bg: tetrieryGreyHover },
      action: { border: 'none', text: blackAction, bg: tetrieryGreyAction },
    },
    [ButtonColors.WhiteBlack]: {
      disabled: { bg: white, text: black, border: 'none' },
      default: { text: white, border: white, bg: 'transparent' },
      hover: { border: 'none', text: whiteHover, bg: tetrieryGreyHover },
      action: { border: 'none', text: whiteAction, bg: tetrieryGreyAction },
    },
    [ButtonColors.Transparent]: {
      default: { text: white, border: blue, bg: 'transparent' },
      disabled: { text: white, border: blue, bg: 'transparent' },
      hover: { text: whiteHover, border: blueHover, bg: 'transparent' },
      action: { bg: 'transparent', text: whiteAction, border: blueAction },
    },
    [ButtonColors.Red]: {
      default: { border: red, bg: 'transparent', text: additionalRed },
      disabled: { border: red, bg: 'transparent', text: additionalRed },
      hover: { bg: tetrieryRed, border: redHover, text: additionalRed },
      action: { border: redAction, text: additionalRed, bg: tetrieryRedHover },
    },
    [ButtonColors.LightGrey]: {
      default: { text: white, bg: 'transparent', border: secondaryGrey },
      hover: { text: white, border: secondaryGrey, bg: secondaryGreyHover },
      action: { text: white, border: secondaryGrey, bg: secondaryGreyAction },
      disabled: {
        text: white,
        bg: 'transparent',
        border: secondaryGrey,
      },
    },
    [ButtonColors.Green]: {
      default: { border: green, bg: 'transparent', text: additionalGreen },
      disabled: { border: green, bg: 'transparent', text: additionalGreen },
      hover: { bg: tetrieryGreen, border: greenHover, text: additionalGreen },
      action: {
        border: greenAction,
        text: additionalGreen,
        bg: tetrieryGreenHover,
      },
    },
    [ButtonColors.Yellow]: {
      default: { border: yellow, bg: 'transparent', text: additionalYellow },
      disabled: { border: yellow, bg: 'transparent', text: additionalYellow },
      hover: {
        bg: tetrieryYellow,
        border: yellowHover,
        text: additionalYellow,
      },
      action: {
        border: yellowAction,
        text: additionalYellow,
        bg: tetrieryYellowHover,
      },
    },
  },

  [ButtonStyleTypes.Ghost]: {
    [ButtonColors.LightRed]: null,
    [ButtonColors.LightGrey]: null,
    [ButtonColors.BlackWhite]: null,
    [ButtonColors.LightGreen]: null,
    [ButtonColors.LightPurple]: null,
    [ButtonColors.LightYellow]: null,
    [ButtonColors.PigmentIndigo]: null,
    [ButtonColors.LightCerulean]: null,
    [ButtonColors.WhiteBlack]: {
      hover: { text: black, border: 'none', bg: whiteHover },
      action: { text: black, border: 'none', bg: whiteAction },
      default: { text: black, border: 'none', bg: 'transparent' },
      disabled: { text: black, border: 'none', bg: 'transparent' },
    },
    [ButtonColors.Red]: {
      default: { text: red, border: 'none', bg: 'transparent' },
      disabled: { text: red, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: redHover, bg: tetrieryRedHover },
      action: { border: 'none', text: redAction, bg: tetrieryRedAction },
    },
    [ButtonColors.WhiteBlue]: {
      disabled: { text: grey, border: 'none', bg: 'transparent' },
      default: { text: white, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blueHover, bg: 'transparent' },
      action: { border: 'none', text: blueAction, bg: 'transparent' },
    },
    [ButtonColors.TetrieryGrey]: {
      disabled: { text: white, border: 'none', bg: tetrieryGrey },
      default: { text: white, border: 'none', bg: 'transparent' },
      hover: { text: white, border: 'none', bg: tetrieryGreyHover },
      action: { text: white, border: 'none', bg: tetrieryGreyAction },
    },
    [ButtonColors.Blue]: {
      default: { text: blue, border: 'none', bg: 'transparent' },
      disabled: { text: blue, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blueHover, bg: tetrieryBlueHover },
      action: { border: 'none', text: blueAction, bg: tetrieryBlueAction },
    },
    [ButtonColors.Transparent]: {
      default: { text: white, border: 'none', bg: 'transparent' },
      disabled: { text: white, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: whiteHover, bg: 'transparent' },
      action: { border: 'none', text: whiteAction, bg: 'transparent' },
    },
    [ButtonColors.Green]: {
      default: { text: green, border: 'none', bg: 'transparent' },
      disabled: { text: green, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: greenHover, bg: tetrieryGreenHover },
      action: { border: 'none', text: greenAction, bg: tetrieryGreenAction },
    },
    [ButtonColors.LightBlue]: {
      default: { text: white, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blueHover, bg: secondaryBlueHover },
      action: { border: 'none', text: blueHover, bg: secondaryBlueHover },
      disabled: {
        border: 'none',
        text: blackAction,
        bg: 'transparent',
      },
    },
    [ButtonColors.Yellow]: {
      default: { border: 'none', bg: 'transparent', text: yellowAction },
      disabled: { border: 'none', bg: 'transparent', text: yellowAction },
      hover: { border: 'none', bg: tetrieryYellow, text: additionalYellow },
      action: {
        border: 'none',
        text: additionalYellow,
        bg: tetrieryYellowHover,
      },
    },
  },

  [ButtonStyleTypes.Standard]: {
    [ButtonColors.Transparent]: null,
    [ButtonColors.Red]: {
      default: { bg: red, text: white, border: 'none' },
      disabled: { bg: red, text: white, border: 'none' },
      hover: { text: white, bg: redHover, border: 'none' },
      action: { text: white, bg: redAction, border: 'none' },
    },
    [ButtonColors.Blue]: {
      default: { bg: blue, text: white, border: 'none' },
      disabled: { bg: blue, text: white, border: 'none' },
      hover: { text: white, bg: blueHover, border: 'none' },
      action: { text: white, border: 'none', bg: blueAction },
    },
    [ButtonColors.Green]: {
      default: { bg: green, text: white, border: 'none' },
      disabled: { bg: green, text: white, border: 'none' },
      hover: { text: white, border: 'none', bg: greenHover },
      action: { text: white, border: 'none', bg: greenAction },
    },
    [ButtonColors.BlackWhite]: {
      default: { bg: black, text: white, border: 'none' },
      disabled: { bg: black, text: white, border: 'none' },
      hover: { text: white, border: 'none', bg: blackHover },
      action: { text: white, border: 'none', bg: blackAction },
    },
    [ButtonColors.WhiteBlack]: {
      default: { bg: white, text: black, border: 'none' },
      disabled: { bg: white, text: black, border: 'none' },
      hover: { text: black, border: 'none', bg: whiteHover },
      action: { text: black, border: 'none', bg: whiteAction },
    },
    [ButtonColors.Yellow]: {
      default: { bg: yellow, text: white, border: 'none' },
      disabled: { bg: yellow, text: white, border: 'none' },
      hover: { text: white, border: 'none', bg: yellowHover },
      action: { text: white, border: 'none', bg: yellowAction },
    },
    [ButtonColors.TetrieryGrey]: {
      default: { text: white, border: 'none', bg: tetrieryGrey },
      disabled: { text: white, border: 'none', bg: tetrieryGrey },
      hover: { text: white, border: 'none', bg: tetrieryGreyHover },
      action: { text: white, border: 'none', bg: tetrieryGreyAction },
    },
    [ButtonColors.WhiteBlue]: {
      default: { text: white, border: 'none', bg: secondaryBlue },
      disabled: { text: white, border: 'none', bg: secondaryBlue },
      hover: { text: white, border: 'none', bg: secondaryBlueHover },
      action: { text: white, border: 'none', bg: secondaryBlueAction },
    },
    [ButtonColors.LightBlue]: {
      default: { text: white, border: 'none', bg: secondaryBlue },
      disabled: { text: white, border: 'none', bg: secondaryBlue },
      hover: { border: 'none', text: whiteHover, bg: secondaryBlueHover },
      action: { border: 'none', text: whiteAction, bg: secondaryBlueAction },
    },
    [ButtonColors.LightPurple]: {
      default: { text: purple, border: 'none', bg: secondaryPurple },
      disabled: { text: purple, border: 'none', bg: secondaryPurple },
      hover: { text: purple, border: 'none', bg: secondaryPurpleHover },
      action: { text: purple, border: 'none', bg: secondaryPurpleAction },
    },
    [ButtonColors.LightGrey]: {
      hover: { border: 'none', bg: secondaryGrey, text: additionalGrey },
      action: { border: 'none', bg: secondaryGrey, text: additionalGrey },
      default: { border: 'none', bg: secondaryGrey, text: additionalGrey },
      disabled: { border: 'none', bg: secondaryGrey, text: additionalGrey },
    },
    [ButtonColors.LightRed]: {
      default: { border: 'none', bg: secondaryRed, text: additionalRed },
      disabled: { border: 'none', bg: secondaryRed, text: additionalRed },
      hover: { border: 'none', text: additionalRed, bg: secondaryRedHover },
      action: { border: 'none', text: additionalRed, bg: secondaryRedAction },
    },
    [ButtonColors.LightYellow]: {
      default: { border: 'none', text: yellowAction, bg: secondaryYellow },
      disabled: { border: 'none', text: yellowAction, bg: secondaryYellow },
      hover: { border: 'none', text: yellowAction, bg: secondaryYellowHover },
      action: { border: 'none', text: yellowAction, bg: secondaryYellowAction },
    },
    [ButtonColors.LightGreen]: {
      default: { border: 'none', bg: secondaryGreen, text: additionalGreen },
      disabled: { border: 'none', bg: secondaryGreen, text: additionalGreen },
      hover: { border: 'none', text: additionalGreen, bg: secondaryGreenHover },
      action: {
        border: 'none',
        text: additionalGreen,
        bg: secondaryGreenAction,
      },
    },
    [ButtonColors.LightCerulean]: {
      default: { border: 'none', text: cerulean, bg: secondaryCerulean },
      disabled: { border: 'none', text: cerulean, bg: secondaryCerulean },
      hover: {
        border: 'none',
        text: cerulean,
        bg: secondaryCeruleanHover,
      },
      action: {
        border: 'none',
        text: cerulean,
        bg: secondaryCeruleanAction,
      },
    },
    [ButtonColors.PigmentIndigo]: {
      default: {
        border: 'none',
        text: pigmentIndigo,
        bg: secondaryPigmentIndigo,
      },
      disabled: {
        border: 'none',
        text: pigmentIndigo,
        bg: secondaryPigmentIndigo,
      },
      hover: {
        border: 'none',
        text: pigmentIndigoHover,
        bg: secondaryPigmentIndigoHover,
      },
      action: {
        border: 'none',
        text: pigmentIndigoAction,
        bg: secondaryPigmentIndigoAction,
      },
    },
  },
});

const input = Object.freeze({
  text: white,
  range: secondaryGrey,
  placeholder: additionalGrey,
  label: { default: white, disabled: additionalGrey },
  bg: { default: tetrieryGrey, disabled: tetrieryGreyHover },
  border: {
    error: red,
    active: blue,
    errorHover: redHover,
    default: secondaryGrey,
    hover: secondaryGreyAction,
    disabled: secondaryGreyHover,
  },
});

const text = Object.freeze({
  [TextColor.Red]: red,
  [TextColor.Blue]: blue,
  [TextColor.Green]: green,
  [TextColor.White]: white,
  [TextColor.Black]: black,
  [TextColor.Inverse]: black,
  [TextColor.Primary]: white,
  [TextColor.Secondary]: grey,
  [TextColor.Mention]: '#2576FA',
});

const select = Object.freeze({
  bg: tetrieryGrey,
  clearIcon: additionalGrey,
  option: { selected: tetrieryGreyHover },
  countryMultiValue: { bg: tetrieryGreyHover },
  multiValue: { text: white, bg: secondaryCerulean },
  text: { default: white, disabled: additionalGrey },
  label: { default: white, disabled: additionalGrey },
  border: {
    error: red,
    active: blue,
    errorHover: redHover,
    disabled: tetrieryGrey,
    default: secondaryGrey,
    hover: secondaryGreyHover,
  },
});

const link = Object.freeze({
  default: blue,
  hover: blueHover,
  bgTextColor: white,
  action: blueAction,
  background: {
    hover: blackHover,
    shadow: cardShadow,
    action: blackAction,
    default: additionalBlack,
  },
});

const tab = Object.freeze({
  badge: white,
  shadow: blackHover,
  text: {
    active: blue,
    disabled: grey,
    default: text.primary,
  },
  count: {
    [TabCountColor.Blue]: blue,
    [TabCountColor.Red]: additionalRed,
  },
  block: {
    bg: {
      active: secondaryBlue,
    },
    text: {
      active: text.primary,
    },
  },
});

const selection = Object.freeze({
  border: blackAction,
  text: {
    disabled: grey,
    selected: blue,
    default: text.primary,
  },
  bg: {
    hover: tetrieryBlue,
    default: tetrieryGrey,
    selected: tetrieryBlue,
    active: tetrieryBlueHover,
  },
});

const eventCard = Object.freeze({
  dot: blue,
  line: blackAction,
  date: additionalGrey,
});

const alert = Object.freeze({
  close: white,
  icon: {
    [types.INFO]: white,
    [types.SUCCESS]: green,
    [types.ERROR]: redAction,
  },
  bg: {
    [types.INFO]: tetrieryBlueHover,
    [types.ERROR]: tetrieryRedHover,
    [types.SUCCESS]: tetrieryGreenHover,
  },
});

const popup = Object.freeze({
  bg: black,
  shadow: regularShadow,
  modal: `${white}66` as string,
  black: { bg: white, text: black },
  modal96: `${tetrieryGrey}F5` as string,
});

const pagination = Object.freeze({
  container: {
    shadow: blackHover,
  },
  arrow: {
    default: white,
    disabled: grey,
  },
  number: {
    selected: blue,
    hover: secondaryBlue,
    active: secondaryBlueHover,
  },
});

const table = Object.freeze({
  innerDivider: tetrieryGreyHover,

  head: {
    title: additionalGrey,
    shadow: `inset 0px -1px 0px ${blackHover}`,
  },

  [ListCardStyle.Green]: {
    hover: { text: white, border: 'none', bg: tetrieryGreen },
    action: { text: white, border: 'none', bg: tetrieryGreen },
    default: { text: white, border: 'none', bg: tetrieryGreen },
    selected: { text: white, border: 'none', bg: tetrieryGreen },
    disabled: { border: 'none', bg: tetrieryGreen, text: additionalGrey },
  },

  [ListCardStyle.Default]: {
    default: { bg: black, text: white, border: 'none' },
    hover: { text: white, border: 'none', bg: blackHover },
    action: { text: white, border: 'none', bg: blackAction },
    disabled: { bg: black, border: 'none', text: additionalGrey },
    selected: {
      bg: black,
      text: white,
      border: `1px solid ${secondaryGrey}`,
    },
  },

  [ListCardStyle.LightRed]: {
    hover: { text: black, border: 'none', bg: tetrieryRed },
    action: { text: black, border: 'none', bg: tetrieryRed },
    default: { text: black, border: 'none', bg: tetrieryRed },
    selected: { text: black, border: 'none', bg: tetrieryRed },
    disabled: {
      border: 'none',
      text: additionalGrey,
      bg: tetrieryRedAction,
    },
  },

  [ListCardStyle.LightCerulean]: {
    default: { text: white, border: 'none', bg: tetrieryCerulean },
    disabled: { text: white, border: 'none', bg: tetrieryCerulean },
    selected: { text: white, border: 'none', bg: tetrieryCerulean },
    hover: { text: white, border: 'none', bg: tetrieryCeruleanHover },
    action: { text: white, border: 'none', bg: tetrieryCeruleanAction },
  },
  [ListCardStyle.LightPuprle]: {
    hover: { text: black, border: 'none', bg: tetrieryPurple },
    action: { text: black, border: 'none', bg: tetrieryPurple },
    default: { text: black, border: 'none', bg: tetrieryPurple },
    selected: { text: black, border: 'none', bg: tetrieryPurple },
    disabled: {
      border: 'none',
      text: additionalGrey,
      bg: tetrieryPurpleAction,
    },
  },

  [ListCardStyle.Grey]: {
    default: { text: white, border: 'none', bg: tetrieryGrey },
    hover: { text: white, border: 'none', bg: tetrieryGreyHover },
    action: { text: white, border: 'none', bg: tetrieryGreyAction },
    disabled: { border: 'none', bg: tetrieryGrey, text: additionalGrey },
    selected: {
      text: white,
      bg: tetrieryGrey,
      border: `1px solid ${secondaryGrey}`,
    },
  },
});

const filter = Object.freeze({
  shadow: blackHover,
  text: additionalGrey,
  svg: { applied: red, active: greenHover },
});

const slidePanel = Object.freeze({
  border: blackHover,
  overlay: `${black}CC` as string,
  shadow:
    `-4px 0px 24px rgba(0, 0, 0, 0.3), inset 1px 0px 0px ${blackHover}` as string,
});

const numberCard = Object.freeze({
  bg: blackAction,
  border: secondaryGrey,
  subHeading: additionalGrey,
});

const calendar = Object.freeze({
  container: {
    background: 'transparent',
    primaryText: text.primary,
    secondaryText: text.secondary,
  },

  day: {
    currentDay: {
      textColor: blue,
      hoverTextColor: blueHover,
    },

    dateBetween: {
      textColor: white,
      hoverTextColor: whiteHover,
      backgroundColor: tetrieryBlue,
    },

    borderRadius: {
      all: '8px 8px 8px 8px',
      left: '8px 0px 0px 8px',
      none: '0px 0px 0px 0px',
      right: '0px 8px 8px 0px',
    },

    selectedDay: {
      textColor: white,
      backgroundColor: blue,
      hoverTextColor: whiteHover,
      hoverBackgroundColor: blueHover,
    },

    default: {
      opacity: '1.0',
      textColor: white,
      hoverTextColor: whiteHover,
      backgroundColor: 'transparent',
      hoverBackgroundColor: tetrieryBlue,
    },
  },
});

const toastify = Object.freeze({
  bg: white,
  text: black,
});

const dropImage = Object.freeze({
  standard: {
    text: white,
    bg: { default: grey, hover: greyHover, active: greyAction },
  },
  error: {
    text: additionalRed,
    bg: { default: secondaryRed, hover: secondaryRedHover },
  },
});

const numpad = Object.freeze({
  border: secondaryGrey,
  number: {
    disabled: {
      text: input.placeholder,
    },
    selected: {
      text: text.white,
      bg: {
        default: blue,
        hover: blueHover,
        active: blueAction,
      },
    },
    default: {
      text: 'inherit',
      bg: {
        hover: blackHover,
        default: 'inherit',
        active: blackAction,
      },
    },
    filled: {
      text: 'inherit',
      bg: {
        default: secondaryBlue,
        hover: secondaryBlueHover,
        active: secondaryBlueAction,
      },
    },
  },
});

const booleanBadge = Object.freeze({
  negative: {
    icon: red,
    bg: tetrieryRedHover,
  },
  positive: {
    bg: tetrieryGreen,
    icon: additionalGreen,
  },
});

const error = Object.freeze({
  color: red,
});

const tree = Object.freeze({
  line: secondaryGreyHover,
});

const infoBlock = Object.freeze({
  background: black,
  hover: blackHover,
  shadow: infoShadow,
  action: blackAction,
});

const chat = Object.freeze({
  shadow: tetrieryGrey,
  main: {
    bg: greyAction,
  },
  icon: {
    bg: black,
    color: blue,
  },
  placeholder: {
    text: additionalGrey,
  },
});

const graph = Object.freeze({
  gradient: `${grey} 70%, ${tetrieryGrey} 0 100% `,
});

const settingRow = Object.freeze({
  border: blackAction,
});

const errorColor = Object.freeze({
  [ErrorColor.Orange]: {
    text: black,
    icon: black,
    background: orange,
  },
  [ErrorColor.Blue]: {
    text: white,
    icon: white,
    background: blueAction,
  },
  [ErrorColor.Green]: {
    text: white,
    icon: white,
    background: greenAction,
  },
  [ErrorColor.Grey]: {
    text: white,
    icon: white,
    background: secondaryGrey,
  },
  [ErrorColor.LightRed]: {
    text: white,
    icon: white,
    background: secondaryRed,
  },
  [ErrorColor.Black]: {
    text: white,
    icon: white,
    background: additionalBlack,
  },
  [ErrorColor.Red]: {
    text: white,
    icon: white,
    background: secondaryRedHover,
  },
  [ErrorColor.LightBlue]: {
    icon: blue,
    text: black,
    background: secondaryBlue,
  },
  [ErrorColor.Yellow]: {
    text: white,
    icon: white,
    background: secondaryYellowHover,
  },
  [ErrorColor.LightGreen]: {
    text: white,
    icon: white,
    background: secondaryGreenHover,
  },
});

const progressBar = Object.freeze({
  text: white,
  icon: { done: blue, default: white },
  stick: {
    done: blue,
    default: grey,
  },
});

const bottomTab = Object.freeze({
  dash: blue,
  icon: { active: blue, default: white, disabled: secondaryGrey },
});

const fieldCard = Object.freeze({
  shadow:
    '0 8px 24px rgba(0, 0, 0, 0.4), 0 8px 24px rgba(0, 0, 0, 0.12)' as string,
});

const eventModal = Object.freeze({
  shadow: '0px 8px 16px rgba(0, 0, 0, 0.04);',
});

const teamMember = Object.freeze({
  [TeamMemberColor.Blue]: {
    hover: { bg: secondaryBlueHover },
    default: { color: blue, bg: secondaryBlue },
    selected: { border: blue, bg: secondaryBlue },
    disabled: { bg: secondaryGrey, text: additionalGrey },
  },
  [TeamMemberColor.Orange]: {
    hover: { bg: tetrieryOrangeAction },
    default: { color: orange, bg: tetrieryOrange },
    selected: { border: orange, bg: tetrieryOrange },
    disabled: { bg: secondaryGrey, text: additionalGrey },
  },
  [TeamMemberColor.Purple]: {
    hover: { bg: secondaryPurpleHover },
    default: { color: purple, bg: secondaryPurple },
    selected: { border: purple, bg: secondaryPurple },
    disabled: { bg: secondaryGrey, text: additionalGrey },
  },
  [TeamMemberColor.Red]: {
    hover: { bg: secondaryRedHover },
    default: { bg: secondaryRed, color: additionalRed },
    disabled: { bg: secondaryGrey, text: additionalGrey },
    selected: { bg: secondaryRed, border: additionalRed },
  },
  [TeamMemberColor.Green]: {
    hover: { bg: secondaryGreenHover },
    disabled: { bg: secondaryGrey, text: additionalGrey },
    default: { bg: secondaryGreen, color: additionalGreen },
    selected: { bg: secondaryGreen, border: additionalGreen },
  },
  [TeamMemberColor.Yellow]: {
    hover: { bg: secondaryYellowHover },
    disabled: { bg: secondaryGrey, text: additionalGrey },
    default: { bg: secondaryYellow, color: additionalYellow },
    selected: { bg: secondaryYellow, border: additionalYellow },
  },
  [TeamMemberColor.Cerulean]: {
    hover: { bg: secondaryCeruleanHover },
    disabled: { bg: secondaryGrey, text: additionalGrey },
    default: { bg: secondaryCerulean, color: additionalCerulean },
    selected: {
      bg: secondaryCerulean,
      border: additionalCerulean,
    },
  },
});

const barChart = Object.freeze({
  axisLine: blackAction,
  textColor: text.primary,
  defaultBarColor: additionalGrey,
  gridLineStroke: tetrieryGreyAction,
  tooltip: {
    bg: blackHover,
    color: text.primary,
  },
});

const rangeSlider = Object.freeze({
  track: blue,
  handle: blue,
  rail: tetrieryGrey,
  tooltip: {
    bg: white,
    color: black,
  },
});

const reaction = Object.freeze({
  text: {
    includesMy: black,
  },
  background: {
    includesMy: white,
    grey: secondaryGrey,
  },
});

export const dotsLoader = Object.freeze({
  [DotsColor.Primary]: white,
  [DotsColor.Grey]: additionalGrey,
});

const avatarColor = Object.freeze({
  [AvatarColor.Orange]: {
    icon: orange,
    textColor: orangeAction,
    backgroundColor: tetrieryOrange,
    backgroundHover: tetrieryOrangeHover,
    backgroundActive: tetrieryOrangeAction,
  },
  [AvatarColor.Blue]: {
    icon: additionalBlue,
    textColor: additionalBlue,
    backgroundColor: secondaryBlue,
    backgroundHover: secondaryBlueHover,
    backgroundActive: secondaryBlueAction,
  },
  [AvatarColor.Cerulean]: {
    icon: cerulean,
    textColor: cerulean,
    backgroundColor: secondaryCerulean,
    backgroundHover: secondaryCeruleanHover,
    backgroundActive: secondaryCeruleanAction,
  },
  [AvatarColor.Green]: {
    icon: additionalGreen,
    textColor: additionalGreen,
    backgroundColor: secondaryGreen,
    backgroundHover: secondaryGreenHover,
    backgroundActive: secondaryGreenAction,
  },
  [AvatarColor.Pink]: {
    icon: additionalPurple,
    textColor: additionalPurple,
    backgroundColor: secondaryPurple,
    backgroundHover: secondaryPurpleHover,
    backgroundActive: secondaryPurpleAction,
  },
  [AvatarColor.Yellow]: {
    icon: additionalYellow,
    textColor: additionalYellow,
    backgroundColor: secondaryYellow,
    backgroundHover: secondaryYellowHover,
    backgroundActive: secondaryYellowAction,
  },
});

const eventCalendar = Object.freeze({
  border: whiteHover,
});

const cashCard = Object.freeze({
  [CashCardType.Out]: {
    icon: red,
    background: tetrieryRed,
  },
  [CashCardType.Cash]: {
    icon: orange,
    background: tetrieryOrange,
  },
  [CashCardType.Expenses]: {
    icon: orange,
    background: tetrieryOrange,
  },
  [CashCardType.Balance]: {
    icon: green,
    background: secondaryGreenAction,
  },
});

const salesCompatition = Object.freeze({
  [SalesCompatitionPosition.Second]: {
    text: white,
    background: secondaryGrey,
  },
  [SalesCompatitionPosition.First]: {
    text: white,
    background: secondaryYellow,
  },
  [SalesCompatitionPosition.Third]: {
    text: white,
    background: secondaryOrange,
  },
});

const eventInventory = Object.freeze({
  [EventInventory.End]: {
    background: secondaryRed,
  },
  [EventInventory.Sold]: {
    background: secondaryGreen,
  },
  [EventInventory.Start]: {
    background: secondaryBlue,
  },
});

const DARK_CONFIG = Object.freeze({
  tab,
  chat,
  tree,
  link,
  text,
  alert,
  error,
  badge,
  input,
  popup,
  table,
  graph,
  common,
  avatar,
  button,
  circle,
  filter,
  select,
  numpad,
  subMenu,
  sidebar,
  reaction,
  cashCard,
  calendar,
  checkbox,
  toastify,
  barChart,
  fieldCard,
  bottomTab,
  infoBlock,
  eventCard,
  dropImage,
  selection,
  eventModal,
  errorColor,
  numberCard,
  pagination,
  slidePanel,
  teamMember,
  settingRow,
  dotsLoader,
  avatarColor,
  rangeSlider,
  progressBar,
  radioButton,
  booleanBadge,
  eventCalendar,
  switchCheckbox,
  eventInventory,
  salesCompatition,
  background: black,
  border: blackHover,
});

export { DARK_CONFIG };
