import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { EventExpense } from 'types';
import { apiQuery } from 'store/query';
import { addParamsToUrl } from 'utils/query';

import { eventApi, tagTypes as eventTagTypes } from '../event';

import {
  EventExpenseListRequest,
  EventExpenseCreateRequest,
  EventExpenseUpdateRequest,
} from './types';

const tagTypes = ['eventExpenses'];

const invalidateEventTag = async <T>(
  args: T,
  { dispatch, queryFulfilled }: any
) => {
  try {
    await queryFulfilled;
    dispatch(eventApi.util.invalidateTags(eventTagTypes));
  } catch (error) {
    console.error(error);
  }
};

export const eventExpensesApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'eventExpensesApi',
  endpoints: (build) => ({
    details: build.query<EventExpense, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.EVENT_EXPENSE_DETAILS, { id }),
      }),
    }),

    list: build.query<EventExpense[], EventExpenseListRequest>({
      providesTags: tagTypes,
      query: (query) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_EXPENSES, query),
      }),
    }),

    delete: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      onQueryStarted: invalidateEventTag,
      query: (id) => ({
        method: 'delete',
        url: generatePath(URL.EVENT_EXPENSE_DETAILS, { id }),
      }),
    }),

    create: build.mutation<EventExpense, EventExpenseCreateRequest>({
      invalidatesTags: tagTypes,
      onQueryStarted: invalidateEventTag,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.EVENT_EXPENSES,
      }),
    }),

    update: build.mutation<EventExpense, EventExpenseUpdateRequest>({
      invalidatesTags: tagTypes,
      onQueryStarted: invalidateEventTag,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.EVENT_EXPENSE_DETAILS, { id }),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useDeleteMutation,
  useCreateMutation,
  useUpdateMutation,
} = eventExpensesApi;

export type {
  EventExpenseListRequest,
  EventExpenseCreateRequest,
  EventExpenseUpdateRequest,
};
