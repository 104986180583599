import styled, { css } from 'styled-components';

import { Icon } from 'components/icon';
import { flexCenter, flexSpaceBetween } from 'styles';
import {
  bodyRegular,
  bodySemibold,
  chatTimeRegular,
} from 'styles/mixins/fonts';

export const PopperContainer = styled.div<{
  title?: string;
  inline?: boolean;
  isYearPicker?: boolean;
  changeByButton?: boolean;
}>`
  ${({ title, theme, inline, isYearPicker, changeByButton }) => css`
    .react-datepicker {
      border: none;
      padding: 16px;
      box-shadow: ${!inline && theme.config.infoBlock.shadow};
      background-color: ${inline ? 'unset' : theme.config.background};

      ${theme.responsive.isMobile &&
      css`
        height: 100vh;
        flex-wrap: wrap;
        overflow-y: auto;
      `};

      &-popper {
        z-index: 100;
      }

      &-wrapper {
        width: 100%;
      }

      ${inline &&
      css`
        width: 100%;
      `}

      ${changeByButton &&
      css`
        display: flex;
        padding-bottom: 80px;
      `}

      ${title &&
      css`
        padding-top: 64px;
      `}

      .react-datepicker__month-container {
        width: ${inline
          ? '100%'
          : theme.responsive.isMobile
            ? 'calc(100vw - 24px)'
            : '312px'};
      }

      &__day--range-start,
      &__day--selecting-range-start {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      &__day--range-end {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }

      &__day--today {
        color: ${theme.config.calendar.day.selectedDay
          .backgroundColor} !important;
      }

      &__day--selected,
      &__day--range-start,
      &__day--range-end,
      &__month-text--selected,
      &__month-text--in-selecting-range,
      &__month-text--in-range,
      &__day--keyboard-selected,
      &__month-text--keyboard-selected {
        color: ${theme.config.calendar.day.selectedDay.textColor} !important;
        background-color: ${theme.config.calendar.day.selectedDay
          .backgroundColor} !important;
      }

      &__day--selected,
      &__day--range-start,
      &__day--range-end,
      &__month-text--selected,
      &__month-text--in-selecting-range,
      &__month-text--in-range,
      &__day--keyboard-selected,
      &__month-text--keyboard-selected:hover {
        color: ${theme.config.calendar.day.selectedDay
          .hoverTextColor} !important;
        background-color: ${theme.config.calendar.day.selectedDay
          .hoverBackgroundColor} !important;
      }

      &__day--in-range,
      &__day--in-selecting-range {
        border-radius: 0px;
        color: ${theme.config.calendar.day.dateBetween.textColor};
        background-color: ${theme.config.calendar.day.dateBetween
          .backgroundColor};
      }

      &__day--disabled {
        opacity: 0.3;
      }

      &__day--outside-month {
        color: ${theme.colors.grey.secondary.action} !important;
        background: none !important;
      }

      ${isYearPicker &&
      css`
        &__header {
          display: none;
        }
      `}

      &__year {
        ${flexCenter};
        margin: 0;
        &-wrapper {
          ${flexSpaceBetween};
        }
      }

      &__navigation-icon {
        &:before {
          border-color: ${theme.colors.blue.primary.standard};
          &:hover {
            border-color: ${theme.colors.blue.primary.hover};
          }
          &:active {
            border-color: ${theme.colors.blue.primary.action};
          }
        }
      }
    }

    .react-datepicker__header {
      background-color: transparent;
      border: none;
      padding: 0;
    }

    .react-datepicker__day-names {
      margin-bottom: 0;
      ${chatTimeRegular};

      .react-datepicker__day-name {
        margin: 0;
        width: calc(100% / 7);
        color: ${theme.config.input.placeholder};
        height: 24px;
      }
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__week {
      display: flex;
    }

    .react-datepicker__day {
      margin: 0;
      display: flex;
      align-items: center;
      aspect-ratio: 1 / 1;
      width: calc(100% / 7);
      justify-content: center;
      color: ${theme.config.text.primary};
      ${bodyRegular};

      &:not(.react-datepicker__day--in-range):not(
          .react-datepicker__day--in-selecting-range
        ) {
        border-radius: 8px;
      }

      &:not(.react-datepicker__day--disabled):hover {
        color: ${theme.config.calendar.day.default.hoverTextColor};
        background-color: ${theme.config.calendar.day.default
          .hoverBackgroundColor};
      }
    }
  `}
`;

export const Calendar = styled(Icon.Calendar)`
  ${({ theme }) => css`
    path,
    fill {
      fill: ${theme.colors.blue.primary.standard} !important;
    }
  `}
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    ${bodySemibold};
    ${({ theme }) => css`
      color: ${theme.config.text.primary};
    `}
  }
`;

export const Footer = styled.div<{
  monthsShown?: number;
}>`
  ${({ theme, monthsShown }) => css`
    right: 0;
    bottom: 0;
    position: absolute;
    padding: 16px 24px;
    ${theme.responsive.isMobile &&
    css`
      width: 100%;
      display: flex;
      background-color: ${theme.config.background};
    `};
    button {
      ${!theme.responsive.isMobile && 'width: 112px;'};
      &:not(:first-of-type) {
        margin-left: 24px;
      }
    }
    ${monthsShown === 1 &&
    css`
      width: 100%;
      display: flex;
      button {
        flex: 1;
      }
    `}
  `}
`;

export const Sidebar = styled.div`
  ${({ theme }) => css`
    display: flex;
    list-style: none;
    align-items: center;
    ${!theme.responsive.isMobile &&
    css`
      width: 140px;
      padding: 0 24px;
      flex-direction: column;
    `};

    ${theme.responsive.isMobile &&
    css`
      overflow-x: auto;
      border-bottom: 1px solid ${theme.config.common.border};
    `};

    li {
      padding: 8px 0;
      button {
        padding: 4px 12px;
      }
    }
  `}
`;

export const Title = styled.h3`
  position: absolute;
  left: 24px;
  top: 24px;
  right: 24px;
  ${({ theme }) => css`
    color: ${theme.config.text.primary};
  `}
`;
