import { Messages } from 'config';
import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { capitalize } from 'utils/string';
import { Feature, PayoutType } from 'types';
import { formatPrice } from 'utils/helpers';
import { selectAuthUser } from 'store/slices/auth';
import { Payout, useListQuery, useDeleteMutation } from 'store/api/payout';
import { useFilter, useShowMore, handleMutation, useSearchQuery } from 'hooks';
import {
  Flex,
  ListCard,
  ListHeader,
  DeleteDialog,
  PayoutStatusBadge,
} from 'components';

import * as CommonStyles from '../../styles';
import { CreateBonusAdvanceDialog } from '../create-bonus-advance-dialog';

import { BonusesProps, CommonListProps } from './types';

const template = 'repeat(5, 1fr) 24px';
const titles = ['Expense itemized', 'Event name', 'Rep', 'Amount'];

const CommonList = ({
  data,
  eventId,
  isAdvances,
  mapQueryProps,
}: CommonListProps) => {
  const authUser = useAppSelector(selectAuthUser);
  const { debounceQuery, ...searchProps } = useSearchQuery();
  const filteredData = useFilter<Payout>({
    data: data || [],
    query: debounceQuery,
    paths: ['event.name', 'amount', 'employee.user.name', 'date'],
  });

  const title = isAdvances ? 'advance' : 'bonus';
  const canCreateBonus = authUser?.features?.includes(Feature.EVENT_MANAGER);

  const [remove, mutation] = useDeleteMutation();
  handleMutation({
    ...mutation,
    successMessage: `${capitalize(title)} ${Messages.DELETED_SUCCESSFULLY}`,
  });

  const { visibleData, ...showMoreProps } = useShowMore(filteredData);

  return (
    <CommonStyles.StyledList
      {...mapQuery(mapQueryProps)}
      {...searchProps}
      {...showMoreProps}
      count={visibleData?.length}
      header={<ListHeader titles={titles} template={template} />}
      searchBarRightSlot={
        canCreateBonus ? (
          <CreateBonusAdvanceDialog eventId={eventId} isAdvances={isAdvances} />
        ) : undefined
      }
    >
      {visibleData?.map((value) => (
        <ListCard
          padding="16px"
          key={value.id}
          headers={titles}
          template={template}
          values={[
            value?.type,
            value?.event?.name,
            value?.employee?.user?.name,
            formatPrice(value?.amount),
            <PayoutStatusBadge type={value?.type} status={value?.status} />,
            <DeleteDialog
              id={value?.id}
              action={remove}
              title={`Delete ${title}`}
              name={`${formatPrice(value?.amount)}`}
            />,
          ]}
        />
      ))}
    </CommonStyles.StyledList>
  );
};

export const Bonuses = ({ event }: BonusesProps) => {
  const { data, ...props } = useListQuery({
    eventId: String(event.id),
    types: JSON.stringify([
      PayoutType.Bonus,
      PayoutType.Advance,
      PayoutType.PrepaidBonus,
      PayoutType.PrepaidAdvance,
    ]),
  });

  const commonProps = {
    eventId: event.id,
    mapQueryProps: props,
  };

  return (
    <Flex gap={24} flexDirection="column">
      <CommonList {...commonProps} data={data?.bonuses} />
      <CommonList {...commonProps} isAdvances data={data?.advances} />
    </Flex>
  );
};
