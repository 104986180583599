export enum ExpensesType {
  Bonus = 'bonus',
  Advance = 'advance',
  Expense = 'expense',
  Reimbursement = 'reimbursement',
}

export interface FormValues {
  date: string;
  notes: string;
  amount: string;
  eventId?: number;
  employeeId: string;
  type?: ExpensesType;
  isCashFromEvent: boolean;
}

export interface CreateBonusAdvanceDialogProps {
  eventId?: number;
  customTitle?: string;
  isAdvances?: boolean;
  isOnlyCashLocation?: boolean;
}

export enum ExpenseTypeCashStatus {
  Prepaid = 'Prepaid',
  Regular = 'Regular',
}
