import * as Yup from 'yup';

import { PayoutType } from 'types';

import { FormValues, ExpensesType, ExpenseTypeCashStatus } from './types';

export const getInitialValues = (
  eventId?: number,
  isOnlyCashLocation?: boolean
): FormValues => ({
  eventId,
  date: '',
  notes: '',
  amount: '',
  employeeId: '',
  isCashFromEvent: isOnlyCashLocation || false,
  type: isOnlyCashLocation ? ExpensesType.Bonus : undefined,
});

export const getValidationSchema = (isOnlyCashLocation?: boolean) => {
  const typeValidation = isOnlyCashLocation
    ? Yup.string().required()
    : Yup.string();

  return Yup.object().shape({
    type: typeValidation,
    date: Yup.date().required(),
    isCashFromEvent: Yup.boolean(),
    eventId: Yup.number().required(),
    employeeId: Yup.number().required(),
    amount: Yup.number().min(1).required(),
    notes: Yup.string().min(5).max(200).required(),
  });
};

export const expenseTypeToPayoutMapping: Record<
  ExpensesType,
  Record<ExpenseTypeCashStatus, PayoutType>
> = {
  [ExpensesType.Bonus]: {
    [ExpenseTypeCashStatus.Regular]: PayoutType.Bonus,
    [ExpenseTypeCashStatus.Prepaid]: PayoutType.PrepaidBonus,
  },
  [ExpensesType.Advance]: {
    [ExpenseTypeCashStatus.Regular]: PayoutType.Advance,
    [ExpenseTypeCashStatus.Prepaid]: PayoutType.PrepaidAdvance,
  },
  [ExpensesType.Expense]: {
    [ExpenseTypeCashStatus.Regular]: PayoutType.Expense,
    [ExpenseTypeCashStatus.Prepaid]: PayoutType.PrepaidExpense,
  },
  [ExpensesType.Reimbursement]: {
    [ExpenseTypeCashStatus.Regular]: PayoutType.Reimbursements,
    [ExpenseTypeCashStatus.Prepaid]: PayoutType.PrepaidReimbursements,
  },
};
