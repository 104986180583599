import styled from 'styled-components';

import { List } from 'components/list';

export const StyledList = styled(List)`
  flex: 1;
  height: unset;
`;

export const Amount = styled.p<{ isNegative?: boolean }>`
  color: ${({ theme, isNegative }) =>
    isNegative ? theme.colors.red.primary.standard : theme.config.text.primary};
`;
