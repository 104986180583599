import { useMemo } from 'react';

import { CashCardType } from 'types';
import { formatPrice } from 'utils/helpers';
import { Flex, InfoBlock } from 'components';

import * as Styles from './styles';
import { getTotalOut } from './helpers';
import { CashIcons, CashTitles } from './config';
import { CashCardProps, CashCardsProps } from './types';

export const CashCard = ({ type, amount, customType }: CashCardProps) => (
  <InfoBlock>
    <Flex gap={16} alignItems="center">
      <Styles.CashIcon type={type}>
        {CashIcons[customType || type]}
      </Styles.CashIcon>
      <Flex gap={8} flexDirection="column">
        <Styles.CashTitle>{CashTitles[customType || type]}</Styles.CashTitle>
        <h3>{formatPrice(amount)}</h3>
      </Flex>
    </Flex>
  </InfoBlock>
);

export const CashCards = ({
  data,
  totalOut,
  customType,
  showOnlyCash,
  customTotalCash,
}: CashCardsProps) => {
  const totalCash = useMemo(
    () =>
      customTotalCash ||
      data.reduce((acc, value) => acc + value.total.total, 0),
    [data, customTotalCash]
  );

  return (
    <Styles.Expenses>
      <CashCard
        amount={totalCash}
        customType={customType}
        type={CashCardType.Cash}
      />
      {!showOnlyCash && (
        <>
          <CashCard amount={totalOut} type={CashCardType.Out} />
          <CashCard type={CashCardType.Balance} amount={totalCash - totalOut} />
        </>
      )}
    </Styles.Expenses>
  );
};

export { getTotalOut };
