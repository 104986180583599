import { formatDate } from 'utils/date';
import { Payout } from 'store/api/payout';
import { formatPrice } from 'utils/helpers';
import { CardValue } from 'components/list-card/types';
import { PayoutStatusBadge } from 'components/payout-status-badge';

import { Card } from './card';
import { TransactionsProps } from './types';
import { TemplateList } from './template-list';

const titles = ['Date', 'Event', 'Amout', 'Notes', 'Status'];
const cellsMobile = [
  { row: '2/3', col: '1/2' },
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '2/3' },
  { row: '3/4', col: '1/3' },
];

export const TransactionsList = ({
  data,
  title,
  extendRender,
  template = '1fr 3fr 1fr 1fr 1fr',
  ...props
}: TransactionsProps) => (
  <TemplateList<Payout>
    data={data}
    title={title}
    headers={titles}
    template={template}
    cellsMobile={cellsMobile}
    render={(value) => {
      const values: CardValue[] = [
        formatDate(value.date),
        value?.event?.name,
        formatPrice(value.amount),
        value?.notes,
        <PayoutStatusBadge type={value?.type} status={value?.status} />,
      ];

      if (extendRender) {
        values.push(...extendRender(value));
      }

      return values;
    }}
    {...props}
  />
);

export { TemplateList, Card as TransactionsCard };
