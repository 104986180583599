import { useMemo } from 'react';

import { Badge } from 'components/badge';
import {
  PayoutType,
  PayoutStatus,
  PayoutStatusColor,
  PayoutStatusTitle,
} from 'types';

interface StatusProps {
  type?: PayoutType;
  status: PayoutStatus;
}

const prepaidTypes = [PayoutType.PrepaidBonus, PayoutType.PrepaidAdvance];

export const PayoutStatusBadge = ({ type, status }: StatusProps) => {
  const badgeText = useMemo(() => {
    if (status === PayoutStatus.Paid && type && prepaidTypes.includes(type)) {
      return 'Paid Cashflow';
    }

    return PayoutStatusTitle[status];
  }, [type, status]);

  return <Badge text={badgeText} styleType={PayoutStatusColor[status]} />;
};
