import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { cartApi } from './api/cart';
import { dealsApi } from './api/deal';
import { salesApi } from './api/sales';
import { usersApi } from './api/users';
import { eventApi } from './api/event';
import { payoutApi } from './api/payout';
import { themesApi } from './api/themes';
import { productApi } from './api/product';
import { companyApi } from './api/company';
import { storagesApi } from './api/storage';
import { authReducer } from './slices/auth';
import { expensesApi } from './api/expenses';
import { supplierApi } from './api/supplier';
import { settingsApi } from './api/settings';
import { carriersApi } from './api/carriers';
import { categoryApi } from './api/category';
import { analyticsApi } from './api/analytics';
import { customersApi } from './api/customers';
import { inventoryApi } from './api/inventory';
import { employeesApi } from './api/employees';
import { loaderReducer } from './slices/loader';
import { accountingApi } from './api/accounting';
import { attributesApi } from './api/attributes';
import { eventOrdersApi } from './api/event-orders';
import { stockOrdersApi } from './api/stock-orders';
import { appointmentsApi } from './api/appointments';
import { productTypesApi } from './api/product-types';
import { priceApprovalApi } from './api/price-approval';
import { eventExpensesApi } from './api/event-expenses';
import { stockTransfersApi } from './api/stock-transfers';
import { paymentMethodsApi } from './api/payment-methods';
import { commissionLevelApi } from './api/commission-level';

const apis = [
  cartApi,
  eventApi,
  usersApi,
  salesApi,
  dealsApi,
  payoutApi,
  themesApi,
  productApi,
  companyApi,
  expensesApi,
  carriersApi,
  supplierApi,
  categoryApi,
  settingsApi,
  storagesApi,
  customersApi,
  analyticsApi,
  inventoryApi,
  employeesApi,
  attributesApi,
  accountingApi,
  stockOrdersApi,
  eventOrdersApi,
  productTypesApi,
  appointmentsApi,
  priceApprovalApi,
  eventExpensesApi,
  stockTransfersApi,
  paymentMethodsApi,
  commissionLevelApi,
];

const appReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  cartApi: cartApi.reducer,
  eventApi: eventApi.reducer,
  usersApi: usersApi.reducer,
  salesApi: salesApi.reducer,
  dealsApi: dealsApi.reducer,
  payoutApi: payoutApi.reducer,
  themesApi: themesApi.reducer,
  productApi: productApi.reducer,
  companyApi: companyApi.reducer,
  expensesApi: expensesApi.reducer,
  carriersApi: carriersApi.reducer,
  supplierApi: supplierApi.reducer,
  categoryApi: categoryApi.reducer,
  settingsApi: settingsApi.reducer,
  storagesApi: storagesApi.reducer,
  customersApi: customersApi.reducer,
  analyticsApi: analyticsApi.reducer,
  inventoryApi: inventoryApi.reducer,
  employeesApi: employeesApi.reducer,
  attributesApi: attributesApi.reducer,
  accountingApi: accountingApi.reducer,
  stockOrdersApi: stockOrdersApi.reducer,
  eventOrdersApi: eventOrdersApi.reducer,
  productTypesApi: productTypesApi.reducer,
  appointmentsApi: appointmentsApi.reducer,
  priceApprovalApi: priceApprovalApi.reducer,
  eventExpensesApi: eventExpensesApi.reducer,
  stockTransfersApi: stockTransfersApi.reducer,
  paymentMethodsApi: paymentMethodsApi.reducer,
  commissionLevelApi: commissionLevelApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/clearAuth') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apis.map((api) => api.middleware)),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
