import { Payout, TransformPayoutData } from 'store/api/payout';

const getTotalAmount = (data?: Payout[]) =>
  data?.reduce?.((acc, value) => acc + +value.amount, 0) || 0;

export const getTotalOut = (expenses?: TransformPayoutData) => {
  const bonuses = getTotalAmount(expenses?.bonuses);
  const advances = getTotalAmount(expenses?.advances);
  const reimbursements = getTotalAmount(expenses?.reimbursements);

  return reimbursements + bonuses + advances;
};
