import styled, { css } from 'styled-components';

import { flexCenter } from 'styles';
import { CashCardType } from 'types';
import { captionRegular } from 'styles/mixins/fonts';

export const Expenses = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => (theme.responsive.isDesktop ? '396px' : '100%')};
`;

export const CashTitle = styled.p`
  ${captionRegular};
  color: ${({ theme }) => theme.colors.grey.primary.standard};
`;

export const CashIcon = styled.div<{ type: CashCardType }>`
  ${flexCenter};
  ${({ type, theme }) => css`
    width: 56px;
    height: 56px;
    border-radius: 16px;
    background: ${theme.config.cashCard[type].background};
    svg path {
      fill: ${theme.config.cashCard[type].icon};
    }
  `}
`;
