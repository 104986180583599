import * as Yup from 'yup';

import { EventExpense } from 'types';

import { FormValues } from './types';

export const getInitialValues = (data?: EventExpense): FormValues =>
  data ?? {
    notes: '',
    amount: '',
    categoryId: 0,
    paymentMethodId: 0,
  };

export const validationSchema = Yup.object().shape({
  categoryId: Yup.number().required(),
  notes: Yup.string().min(6).required(),
  paymentMethodId: Yup.number().required(),
  amount: Yup.number().required().typeError('Amount must be a valid number'),
});
