import { BadgeColor } from 'types';
import { Flex } from 'components/flex';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { InfoBlock } from 'components/info-block';
import { BackButton } from 'components/specified-buttons';
import { PayoutStatusBadge } from 'components/payout-status-badge';

import * as Styles from './styles';
import { PaymentDetailsProps } from './types';

export const PaymentDetails = ({
  data,
  title,
  children,
  withoutPadding,
  extendsSections,
  ...props
}: PaymentDetailsProps) => (
  <>
    <BackButton text={`${title} details`} />

    <Styles.StyledGeneralInfo
      withoutPhoto
      withPlaceholders
      name={data?.event?.name}
      stickyWithHeader={false}
      sectionWrapper={InfoBlock}
      withoutPadding={withoutPadding}
      containerStyles={{ gap: '16px' }}
      {...props}
      sections={[
        {
          rows: [
            [
              {
                col: 3,
                colTablet: 6,
                title: 'Event',
                value: data?.event?.name,
              },
              {
                col: 3,
                colTablet: 6,
                title: 'Expense',
                value: formatPrice(data?.amount),
              },
              {
                col: 3,
                colTablet: 6,
                title: 'Date',
                value: formatDate(data?.date),
              },
              {
                col: 3,
                colTablet: 6,
                title: 'Status',
                value: data?.status && (
                  <PayoutStatusBadge type={data?.type} status={data.status} />
                ),
              },
            ],
          ],
        },
        {
          rows: [
            [
              {
                col: 6,
                noEllipsis: true,
                value: data?.notes,
                title: 'Description',
              },
              extendsSections || {},
            ],
          ],
        },
      ]}
    />

    {data?.reasonReject && (
      <Styles.BadgeContainer>
        <Styles.StyledBadge
          styleType={BadgeColor.Red}
          text={
            <Flex flexDirection="column">
              <p className="semibold">Reasons for rejection</p>
              <p>{data?.reasonReject}</p>
            </Flex>
          }
        />
      </Styles.BadgeContainer>
    )}

    {children}
  </>
);

export type { PaymentDetailsProps };
