import { PopupProps } from 'components';
import { Event, EventReport } from 'store/api/event';

export enum Tab {
  Revenue = 'revenue',
  Expenses = 'expenses',
  CashFlow = 'cashFlow',
  // TODO: uncomment when it was ready
  // Received = 'received',
  Inventory = 'inventory',
  Bonuses = 'bonuses',
  Reimbursements = 'reimbursements',
  Commissions = 'commissions',
  ProfitAndLosses = 'profitAndLosses',
  StockOrders = 'stockOrders',
}

export const TabTitles = {
  [Tab.Revenue]: 'Revenue',
  [Tab.Expenses]: 'Expenses',
  [Tab.CashFlow]: 'Cashflow',
  [Tab.Inventory]: 'Inventory',
  [Tab.ProfitAndLosses]: 'P&L',
  [Tab.Commissions]: 'Commissions',
  [Tab.StockOrders]: 'Stock Orders',
  // [Tab.Received]: 'Money received',
  [Tab.Bonuses]: 'Bonuses & Advances',
  [Tab.Reimbursements]: 'Reimbursements',
};

export interface CommonReportProps {
  data: EventReport[];
}

export interface RevenueProps extends CommonReportProps {
  event: Event;
}

export interface ExpensesProps extends CommonReportProps {
  event: Event;
}

export interface CashFlowProps extends CommonReportProps {
  event: Event;
}

export interface PopupListProps extends Pick<PopupProps, 'trigger'> {
  count: number;
  headerTitles: string[];
  elements: JSX.Element[];
}
