/* eslint-disable perfectionist/sort-objects */
export const URL = {
  LOGIN: `users/login`,
  LOGOUT: `users/logout`,
  REFRESH: `users/refresh`,
  USERS: 'users',
  USER_DETAILS: 'users/:id',
  USER_ROLES: 'users/roles',
  USER_APPROVAL_PASSWORD: 'users/:id/approval-password',

  COMPANY_LIST: 'company',
  COMPANY_DETAILS: 'company/:id',
  MY_COMPANY_INFO: 'company/my-company',
  UPDATE_COMPANY_SETTINGS: 'company/:id/settings',

  EVENT_LIST: 'events',
  EVENT_DETAILS: 'events/:id',
  EVENT_OFFER: 'events/:id/offer',
  EVENT_ACCEPT: 'events/:id/accept',
  EVENT_REPORT: 'events/:id/report',
  EVENT_CHECKOUT: 'events/:id/checkout',
  EVENT_EMPLOYEES: 'events/:id/employees',
  EVENT_INVENTORY_STATISTIC: 'events/:id/inventory',
  EVENT_REPORT_PDF: 'events/:id/report-pdf',
  EVENT_CLOSE_DAY: 'events/:id/open-close-day',
  EVENT_CASH_MANAGER: 'events/:id/cash-manager',
  EVENT_QUESTIONNAIRE: 'events/:id/questionnaire',
  EVENT_RECEIPTS: 'events/:id/receipts',
  EVENT_RECEIPTS_HTML: 'events/:id/receipt/:receiptId/html',
  EVENT_RECEIPT: 'events/:id/receipt/:receiptId',
  EVENT_EMPLOYEE_CHECKOUTS: 'events/:id/employee-checkouts',
  EVENT_GET_TAX_RATE: 'events/tax-rate',
  EVENT_TOP_SELLERS: 'events/top-sellers',
  EVENT_DASHBOARD_STATISTICS: 'events/:id/dashboard-statistics',
  EVENT_EMPLOYEE_COMMISSIONS: 'events/:id/employee-commissions/:userId',
  EVENT_PROFIT_AND_LOSSES: 'events/:id/profit-loss',
  EVENT_STOCK_ORDERS_INFO: 'events/:id/stock-orders-info',

  CUSTOMERS_LIST: 'customers',
  CUSTOMER_DETAILS: 'customers/:id',

  PRODUCT_TYPES: 'product-types',

  PAYMENT_METHODS: 'payment-methods',
  PAYMENT_METHOD: 'payment-methods/:id',

  PRODUCT_LIST: 'products',
  PRODUCT_DETAILS: 'products/:id',

  SUPPLIER_LIST: 'suppliers',
  SUPPLIER_DETAILS: 'suppliers/:id',

  CART: 'cart/event/:id',
  CART_RESET: 'cart/event/:id/reset',
  CART_ADD: 'cart/event/:id/cartItem',
  CART_REMOVE_ITEM: 'cart/event/:id/cartItem/:cartId',

  CATEGORIES: 'categories',
  CATEGORY_DETAILS: 'categories/:id',

  STORAGES: 'storages',
  STORAGE: 'storages/:id',

  EMPLOYEES: 'employees',
  EMPLOYEE: 'employees/:id',
  EMPLOYEE_ME: 'employees/me',

  DEALS: 'deals',
  DEAL: 'deals/:id',

  ATTRIBUTES: 'attributes',
  ATTRIBUTE: 'attributes/:id',

  APPOINTMENTS: 'appointment',
  APPOINTMENT: 'appointment/:id',

  STORAGE_INVENTORY: 'inventory/storage/:id',
  STORAGE_INVENTORY_DETAILS: 'inventory/storage/:id/:inventoryId',

  THEMES: 'themes',

  STOCK_ORDERS: 'inventory/stockorder',
  STOCK_ORDER: 'inventory/stockorder/:id',
  STOCK_ORDER_COMPLETE: 'inventory/stockorder/:id/done',

  GET_SALES_INFO: 'sales/info',
  GET_DEVICES: 'settings/devices',
  GET_INTEGRATIONS: 'settings/integrations',

  STOCK_TRANSFERS: 'inventory/transfer',
  STOCK_TRANSFER: 'inventory/transfer/:id',
  STOCK_TRANSFER_COMPLETE: 'inventory/transfer/:id/complete',
  STOCK_TRANSFER_ACCEPT: 'inventory/transfer/:id/accept',

  GET_PAYOUTS: 'payouts/payoutItem',
  CREATE_PAYROLL: 'payouts/payout',
  UPDATE_PAYOUT: 'payouts/payoutItem/:id',
  GET_PAYOUT_HISTORY: 'payouts/payout/employee/:id',
  GET_PENDING_PAYOUTS: 'payouts/payout/pending/:id',
  GET_PAYOUT_DETAILS: 'payouts/payoutItem/:id/details',
  CHANGE_PAYOUT_STATUS: 'payouts/payoutItem/:id/status',
  GET_EMPLOYEE_SALARY: 'payouts/:id/salary',

  UPLOAD_FILE: 'upload-file',

  CARRIERS: 'carriers',

  GET_ANALYCITS: 'analytics',
  GET_ANALYCITS_EMPLOYEE: 'analytics/employee/:id',
  GET_ANALYCITS_CHECKOUT_EDIT: 'analytics/checkout/:id',

  EVENT_ORDERS: 'event-orders',
  EVENT_ORDER: 'event-orders/:id',
  EVENT_ORDERS_CSV: 'event-orders/csv',
  EVENT_ORDER_CHANGE_STATUS: 'event-orders/:id/change-status',

  PRICE_APPROVAL: 'price-approval',
  PRICE_APPROVAL_DETAILS: 'price-approval/:id',
  PRICE_APPROVAL_CHANGE_STATUS: 'price-approval/:id/status',
  PRICE_APPROVAL_APPROVE: 'price-approval/approve',

  GET_ACCOUNTING_EXPENSES: 'accounting/expenses',
  CREATE_ACCOUNTING_EXPENSE: 'accounting/expense',
  GET_ACCOUNTING_EXPENSE: 'accounting/expense/:id',

  CREATE_ACCOUNTING_CATEGORY: 'accounting/category',
  GET_ACCOUNTING_CATEGORIES: 'accounting/categories',
  GET_ACCOUNTING_CATEGORY: 'accounting/category/:id',

  GET_CHARGEBACKS: 'accounting/chargebacks',
  UPDATE_CHARGEBACK: 'accounting/chargebacks/:id',
  UPDATE_CHARGEBACK_STATUS: 'accounting/chargebacks/:id/status',

  GET_MONTHLY_TAXES: 'accounting/monthly-taxes',
  UPDATE_MONTHLY_TAX: 'accounting/monthly-taxes/:month/:year',

  GET_ACCOUNTING_TRANSACTIONS: 'accounting/transactions',
  ACCOUNTING_MARK_DISPUTE: 'accounting/transactions/dispute/:id',

  GET_ACCOUNTING_EXPENSES_CATEGORIES: 'accounting/expenses/categories',

  GET_ACCOUNTING_DEPOSITS: 'accounting/deposits',

  GET_ACCOUNTING_SALES: 'accounting/sales',

  GET_ACCOUNTING_CHARGEBACKS: 'accounting/chargebacks',

  GET_ACCOUNTING_GIFT_CARDS: 'accounting/gift-cards',

  GET_ACCOUNTING_TAVLA_SUMMARY: 'accounting/tavla/summary',
  GET_ACCOUNTING_TAVLA_PAYMENTS: 'accounting/tavla/payments',
  GET_ACCOUNTING_TAVLA_BACK_OFFICE: 'accounting/tavla/back-office',
  GET_ACCOUNTING_TAVLA_TRANSACTIONS: 'accounting/tavla/transactions',

  GET_ACCOUNTING_REVENUE_CREDIT: 'accounting/revenue/credit',
  GET_ACCOUNTING_REVENUE_SUMMARY: 'accounting/revenue/summary',
  GET_ACCOUNTING_REVENUE_DEPOSITS: 'accounting/revenue/deposits',

  GET_ACCOUNTING_SALES_TAX: 'accounting/taxes',
  GET_ACCOUNTING_SALES_TAX_SUMMARY: 'accounting/sales-tax/summary',

  GET_ACCOUNTING_PROFIT_LOSSES: 'accounting/profit-losses',

  SEND_CHECKOUT_MAIL: 'mailer/checkout',

  GET_COMMISSION_SETS: 'commission-level',
  UPDATE_COMMISSION_SET: 'commission-level/:id',
  GET_DAILY_EMPLOYEE_COMMISSION_LEVEL: 'commission-level/employee/:id',

  EVENT_EXPENSES: 'event-expenses',
  EVENT_EXPENSE_DETAILS: 'event-expenses/:id',
};
