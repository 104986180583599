import { useMemo } from 'react';

import { Messages } from 'config';
import { PayoutType } from 'types';
import { mapQuery } from 'utils/query';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { Payout, useListQuery, useDeleteMutation } from 'store/api/payout';
import { List, Flex, ListCard, ListHeader, DeleteDialog } from 'components';
import { isMobile, useFilter, handleMutation, useSearchQuery } from 'hooks';

import { CashFlowProps } from './types';
import { CashCards, getTotalOut } from './cash-cards';
import { CreateBonusAdvanceDialog } from './create-bonus-advance-dialog';

const footerTabletTemplate = '1fr 1fr';
const template = 'repeat(5, 1fr) 24px';
const titles = ['Date', 'Amount', 'Type', 'Employee', 'Notes', ''];
const paths = [
  'date',
  'type',
  'notes',
  'amount',
  'event.name',
  'employee.user.name',
];
const expensesTypes = [
  PayoutType.PrepaidBonus,
  PayoutType.PrepaidAdvance,
  PayoutType.PrepaidExpense,
  PayoutType.PrepaidReimbursements,
];

export const CashFlow = ({ data, event }: CashFlowProps) => {
  const mobile = isMobile();
  const { debounceQuery, ...search } = useSearchQuery();
  const { data: expenses, ...props } = useListQuery({
    eventId: String(event.id),
    types: JSON.stringify(expensesTypes),
  });

  const currentData = [
    ...(expenses?.bonuses || []),
    ...(expenses?.advances || []),
    ...(expenses?.reimbursements || []),
  ].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const [remove, mutation] = useDeleteMutation();
  handleMutation({
    ...mutation,
    successMessage: `Expense ${Messages.DELETED_SUCCESSFULLY}`,
  });

  const filteredValues = useFilter<Payout>({
    paths,
    data: currentData,
    query: debounceQuery,
  });

  const totalOut = useMemo(() => getTotalOut(expenses), [expenses]);

  return (
    <Flex gap={16} flexDirection={mobile ? 'column' : 'row'}>
      <CashCards data={data} eventId={event.id} totalOut={totalOut} />
      <List
        {...mapQuery(props)}
        {...search}
        count={filteredValues.length}
        mainWrapperStyles={{ padding: 0 }}
        searchContainerStyle={{ margin: '0 0 16px 0' }}
        header={
          <ListHeader padding="8px" titles={titles} template={template} />
        }
        searchBarRightSlot={
          <CreateBonusAdvanceDialog
            eventId={event.id}
            isOnlyCashLocation
            customTitle="Create cashflow"
          />
        }
        footer={
          <ListCard
            selected
            padding="16px"
            template={template}
            tabletTemplate={footerTabletTemplate}
            mobileTemplate={footerTabletTemplate}
            values={['Total', formatPrice(totalOut)]}
          />
        }
      >
        {filteredValues.map((value) => (
          <ListCard
            key={value.id}
            headers={titles}
            template={template}
            values={[
              formatDate(value.date),
              formatPrice(value.amount),
              value.type,
              value.employee?.user?.name,
              value.notes,
              <DeleteDialog
                id={value?.id}
                action={remove}
                title={`Delete ${value.type}`}
                name={`${formatPrice(value?.amount)}`}
              />,
            ]}
          />
        ))}
      </List>
    </Flex>
  );
};
