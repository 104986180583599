import { Flex } from 'components/flex';
import { EmptyPlaceholder } from 'config';
import { InfoBlock } from 'components/info-block';

import * as Styles from './styles';
import { InfoCardProps } from './types';

export const InfoCard = ({
  icon,
  title,
  value,
  className,
  iconCardStyles,
}: InfoCardProps) => (
  <InfoBlock className={className} data-testid="info-card">
    <Flex gap={16} alignItems="center">
      {icon && (
        <Styles.IconContainer {...iconCardStyles}>{icon}</Styles.IconContainer>
      )}
      <Flex minWidth={0} flexDirection="column">
        <Styles.Title>{title}</Styles.Title>
        <h3>{value || EmptyPlaceholder}</h3>
      </Flex>
    </Flex>
  </InfoBlock>
);
