import { useMemo } from 'react';

import { Messages } from 'config';
import { mapQuery } from 'utils/query';
import { formatPrice } from 'utils/helpers';
import { CashCardType, ListCardStyle } from 'types';
import { handleMutation, useSearchQuery } from 'hooks';
import { useListQuery, useDeleteMutation } from 'store/api/event-expenses';
import {
  List,
  Flex,
  Loader,
  ListCard,
  ListHeader,
  DeleteDialog,
} from 'components';

import { ExpensesProps } from '../types';
import { CashCards } from '../cash-cards';

import * as Styles from './styles';
import { EditExpense, CreateExpense } from './form';

const template = '1fr 120px 1fr 1fr 1fr 64px';
const titles = ['Category', 'Amount', 'Payment Method', 'Notes', 'Created by'];

export const Expenses = ({ data, event }: ExpensesProps) => {
  const { debounceQuery, ...search } = useSearchQuery();
  const { data: expenses, ...props } = useListQuery({ search: debounceQuery });

  const totalOut = useMemo(
    () => expenses?.reduce((acc, value) => acc + +value.amount, 0),
    [expenses]
  );

  const [remove, mutation] = useDeleteMutation();
  handleMutation({
    ...mutation,
    successMessage: `Expense ${Messages.DELETED_SUCCESSFULLY}`,
  });

  return (
    <Loader isFullScreen={false} isLoading={props.isLoading}>
      <Flex gap={24} fullHeight>
        <CashCards
          data={data}
          showOnlyCash
          eventId={event.id}
          totalOut={totalOut || 0}
          customTotalCash={totalOut}
          customType={CashCardType.Expenses}
        />

        <Flex gap={24} fullWidth fullHeight flexDirection="column">
          <List
            {...mapQuery(props)}
            {...search}
            count={expenses?.length}
            mainWrapperStyles={{ padding: 0 }}
            searchBarRightSlot={<CreateExpense />}
            searchContainerStyle={{ margin: '16px 0' }}
            header={
              <ListHeader padding="8px 0" titles={titles} template={template} />
            }
            searchInputProps={{
              placeholder:
                'Search category, payment method, amount, notes or created by',
            }}
            footer={
              <ListCard
                selected
                padding="16px"
                template={template}
                even={ListCardStyle.Green}
                values={[
                  <p className="semibold">Total</p>,
                  formatPrice(totalOut),
                ]}
              />
            }
          >
            {expenses?.map((value) => {
              const amount = formatPrice(value.amount);

              return (
                <ListCard
                  key={value.id}
                  padding="16px"
                  template={template}
                  values={[
                    value.category.name,
                    <Styles.Amount isNegative={+value.amount < 0}>
                      {amount}
                    </Styles.Amount>,
                    value?.paymentMethod?.name,
                    value.notes,
                    value?.user?.name,
                    <Flex gap={16}>
                      <EditExpense data={value} />
                      <DeleteDialog
                        id={value.id}
                        action={remove}
                        title="Delete Expense"
                        name={`expense with ${amount}`}
                      />
                    </Flex>,
                  ]}
                />
              );
            })}
          </List>
        </Flex>
      </Flex>
    </Loader>
  );
};
